import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { BounceBallLoader } from "react-bounce-ball-loader";
import { useNavigate } from "react-router-dom";

export default function Loader() {

  const navigate = useNavigate();
  useEffect(()=>{
    if( Cookies.get("response")===null || Cookies.get("ani")===undefined || Cookies.get("response")==="false")
    {
      navigate("/Notsub");
    }
  })
    // const [isLoading, setIsLoading] = useState(false);
  return (
    <>
           <BounceBallLoader />
        </>
  );
}