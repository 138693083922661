import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
const base_api = "http://localhost:3232";
class Temp
{


Sub()
{
  const number = {msisdn: Cookies.get("ani")};
    return axios.get(base_api + "/Checksub", number);
     
}
}
export default new Temp;