import axios from 'axios';
import { useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";
import Loader from '../component/Loader';
import Cookies from 'js-cookie';





const base_api = "https://bigcashcellc.gamewins.co.za";

// const base_api = "http://localhost:3231";


// const getScore = () => {
//     return axios.get(base_api + "/score");
//   };
  
  const getLeaderBoard = () => {
    return axios.get(base_api +"/leader");
  };
  
  // 27740208754
const CheckSub = () => {

  const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(()=>{
   
   
    let params = new URLSearchParams(window.location.search);
    const ani = params.get("cli");
    const serviceId=params.get("sid");
    setIsLoading(true);
    if(ani===undefined || serviceId===undefined)
    {
      navigate("/Notsub");
    }
    else{
      Cookies.set("ani",ani);
    }

  },[])

  function Forsub()
  {
    

      navigate("/main");
     
  }

  function Notsub(){

   
    navigate("/Notsub");
    
  }
  
    // const [val,setval] = useState({});
// console.log("response",window.localStorage.getItem("ani"))
const number = {MSISDN: Cookies.get("ani")};
  // setIsLoading(true);
  // const number = "fsjfg";
  // console.log(number);
    const response =  axios.post(base_api +"/Checksub",number);
      // setval(response.data);
       response.then((response)=>{
        // console.log("response",response.data.Response)
        const result = response.data.Response;
        // console.log("result",result);
        // const check = response.data.session;
        // console.log("check",check);
        console.log("result",result);
        if(result==="true")
    {
      Cookies.set("response",true);
      Forsub();
      // setIsLoading(false);
    }
    else
    {
      Cookies.set("response",false);
      Notsub();
    }
   
       })
  }; 

  const Images=()=>{
    return axios.get(base_api+"/images");
    
  };

  const Terms=()=>{
    return axios.get(base_api+"/terms");
  };


  const Score= ()=>{

    return axios.get(base_api+"/UserScore?msisdn="+Cookies.get("ani"));
  
  
  };

  
export default {getLeaderBoard,CheckSub,Images,Terms,Score};

