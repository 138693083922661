import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import prizeimg from "../images/prize.png";
import menuicon from "../images/menu-icon.png";
import coins from "../images/coins.webp";
import "../css/custom.css";
import "../css/bootstrap.min";
import Footer from "../component/footer.js";
import { useNavigate } from "react-router-dom";
import Api from "../Api/Getapi.js";
import Loader from "../component/Loader.js";
import Terms from "../component/Terms.js";
import { Link } from "react-router-dom";
import Menu from "../component/Menu.js";
import Cookies from "js-cookie";

function Prize() {
  // const [isOpen, setIsOpen] = useState(false);
  // const [term,setTerm] = useState([]);
  // const [faq,setFaq] = useState([]);

  const [isLoading, setIsLoading] = useState();

  // const [swalProps, setSwalProps] = useState({});

  // function handleClick(){
  //   Swal.fire(  );
  // }

  // const closeModal =()=>{
  //   setIsOpen(false);
  // }

  // const openModal = () => {
  //   Api.Terms().then((response)=>{
  //         console.log(response.data.terms[0]);
  //     //     const ReactDOMServer = require('react-dom/server');
  //     //      const HtmlToReactParser = require('html-to-react').Parser;
  //     //       // const record =
  //     //       const htmlInput =  response.data.terms[0];
  //     //       // console.log("htmlInput",htmlInput);
  //     //       const htmlToReactParser = new HtmlToReactParser();
  //     //       const reactElement = htmlToReactParser.parse(htmlInput);
  //     //       const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
  //     // console.log("react Code",reactHtml);
  //   // console.log("inside open model method");

  //   });
  // }

  // const [modal,setModal] = useState(false)
  const [DailyAirtime, setDailyAirtime] = useState([]);
  const [MonthlyAirtime, setMonthlyAirtime] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    Api.Terms().then((response) => {
      if (
        Cookies.get("response") === null || Cookies.get("ani")===undefined || 
        Cookies.get("response") === "false"
      ) {
        navigate("/Notsub");
      }

      // console.log("response",response);
      setDailyAirtime(response.data.dailyAirtime);
      setMonthlyAirtime(response.data.monthlyAirtime);
      // setTerm(response.data.terms[0]);
      // setFaq(response.data.faq[0]);

      setIsLoading(false);
    });
  }, []);

  // const navigate = useNavigate();

  // const TermsCondition=()=>{

  //   console.log("control inside TermsAndCondition");
  // setModal(!modal);
  // <ModalDialog />

  //   // const [Condition,setCondition] = useState([]);
  //   Api.Terms().then((response)=>{
  //     // console.log(response.data.terms[0]);
  //
  //     // setCondition();
  //     // console.log(Condition);
  //   })

  // };

  const checkData = (
    <>
      <Menu
        one="inactive"
        two="inactive"
        three="inactive"
        four="active"
        five="inactive"
        six="inactive"
      />

      <div className="container white-bg">
        <div className="cus-header">
          <div className="col-md-7 col-xs-7">
            <div className="page-icon">
              <img src={prizeimg} alt="prize" /> Prize
            </div>
          </div>
          {/* <div className="col-md-5 col-xs-5"><a className="cus-menu" href="#!"><img src={menuicon} alt="menuicon"/>menu</a>
</div> */}
          <div class="col-md-5 col-xs-5">
            <a
              class="cus-menu navbar-toggle collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <img src={menuicon} alt="menuicon" />
              menu
            </a>
          </div>
        </div>
        <div className="container-area">
          <a href="#!" className="cus-btn-blue">
            {" "}
            Winner Prizes
          </a>
          <p className="big-font">
            R1,000 is the Grand Cash Prize! <br />
            Enjoy Daily Airtime Rewards!
          </p>
          <div className="score-area-table">
            <div className="blue-row">
              <div className="col-md-6 col-xs-6">Daily Airtime </div>
              <div className="col-md-6 col-xs-6">Monthly Cash </div>
            </div>
            <div className="white-bg-shadow light-blue-bg padding-low">
              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 1</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[0]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 1</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[0]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 2</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[1]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 2</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[1]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 3</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[2]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 3</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[2]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 4</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[3]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 4</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[3]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 5</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[4]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 5</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[4]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 6</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[5]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 6</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[5]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 7</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[6]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 7</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[6]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 8</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[7]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 8</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[7]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 9</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[8]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 9</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[8]}</div>
                </div>
              </div>

              <div className="rating-table">
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 10</div>
                  <div className="col-md-7 col-xs-7">{DailyAirtime[9]}</div>
                </div>
                <div className="col-md-6 border-winner col-xs-6">
                  <div className="col-md-5 col-xs-5"> 10</div>
                  <div className="col-md-7 col-xs-7">{MonthlyAirtime[9]}</div>
                </div>
              </div>

              {/* <div className="rating-table"> 
<div className="col-md-6 border-winner col-xs-6"> 
<div className="col-md-5 col-xs-5"> 1</div>
<div className="col-md-7 col-xs-7">R60</div>

</div>
<div className="col-md-6 border-winner col-xs-6"> 
<div className="col-md-5 col-xs-5"> 1</div>
<div className="col-md-7 col-xs-7">R1000</div>
</div>
</div> */}

              <div className="rating-btn">
                 {/* <Terms /> need to hide term and condition for give a demo link */}
                                        {/* <div className="col-md-6 col-xs-6"> 
                        <a href="#!">FAQs <img src={message} alt="message" /></a>
                        </div> */}
                                        {/* <div className="popup-box">
                        <div className="box">
                        <a href="#!" onClick={setIsOpen} ><img src={notes} alt="notes" /> T’s & C’s</a> */}
                                        {/* <ReactModal isOpen={isOpen} contentLabel="Example Modal"  className="Modal">

                        <div style={{ width : '100%'}} >
                        This is sample For Testing  fhsdfhsdfhsdhgjgldfghdfbjhkg ihggjghflhgsglghfsdklhsdghkshkjskshgkshdkghsk
                        </div>
                        <button onClick={()=>{closeModal();}}>Close Modal</button>
                        </ReactModal> */}

                                        {/* </div>
                        </div> */}
                <div className="col-md-12  col-xs-12">
                  {" "}
                  <br />
                  <center>
                    {" "}
                    <img src={coins} alt="coinse" />
                  </center>
                </div>
              </div>
            </div>

            <div className="cus-score-blue">
              <Link to="/home"> Play to Win Big</Link>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );

  return <>{isLoading ? <Loader /> : checkData}</>;
}
export default Prize;
