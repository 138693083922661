import React  from "react";
import '../css/NotSuccess.css';

const Notsub=()=>{

    return (

      <>
       <div className="success-main-div">
      <div className="success-card">
        <div className="success-div-box">
          <i className="success-checkmark">✓</i>
        </div>
        <h1 className="success-heading">Not Subscriber</h1>
        <p className="success-para">
          please Subscribe First....
        </p>
      </div>
    </div>
        </>
    );
};
export default Notsub;