// import logo from './logo.svg';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './Routing/Routing.js';
// import MainMethod from './component/First.js';
// import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
function App() {
 
  return (
   <>
  {/* <MainMethod /> */}
   <Routing />
   </>
  );
}

export default App;
