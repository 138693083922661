import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import Api from "../Api/Getapi.js";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

export default function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [terms, setTerms] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [question, setQuestion] = useState('');


  useEffect(() => {
    Api.Terms().then((response) => {
      console.log("terms", response.data.terms[0]);
      setTerms(response.data.terms[0]);
      setIsOpen(true);
      setQuestion(response.data.faq[0])
    });
  });

  const navigate = useNavigate();
  const closeModal = () => {
    setIsOpen(false);
    navigate("/home");

    //   setOpenModal(false);
  };
  const closeModalForFaq = () => {
    setOpenModal(false);
  };

  const handleOutsideClick = () => {
    if (isOpen || openModal) {
      setIsOpen(false);
      setOpenModal(false);
      navigate("/home");
    }
  };

  return (
    <>

      <div onClick={()=>{handleOutsideClick();}}>
        <ReactModal
          isOpen={isOpen}
          contentLabel="Example Modal"
          className="Modal"
        >
          {/* <div style={{ width : '100%'}} >
{terms}
</div>
<button onClick={()=>{closeModal();}}>Close Modal</button> */}
          <div className="modal-container">
            <div className="modal-content">
              <p className="modal-body">{parse(terms)}</p>
              <div className="modal-footer">
                <button
                  className="modal-close"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
}
