import React from "react";
import message from '../images/messgae.svg';
import notes from '../images/notes.svg';
import Api from '../Api/Getapi.js';
import parse from "html-react-parser";
import '../css/Modal.css';
import '../css/Term.css';
import { useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Terms() {

  const [isOpen, setIsOpen] = useState(false);
  // const [showTerm,setshowTerm] = useState(false);
  const [terms, setTerms] = useState('');
  // const [Faq,setFaq] = useState('');
  const [question, setQuestion] = useState('');
  const [openModal, setOpenModal] = useState('');

  const navigate = useNavigate();
  const faq = () => {

    Api.Terms().then((response) => {
      if(Cookies.get('response')===null || Cookies.get("ani")===undefined ||  Cookies.get("response")==="false")
      {
          navigate("/Notsub");
      }
      console.log("faq", response.data.faq[0])
      setQuestion(response.data.faq[0]);
      setOpenModal(true);

      // console.log(question);
      // setFaq(response.data.faq[0]);
      // console.log(Faq);
      // console.log(response.data.faq[0]);
    })
  }

  const closeModal = () => {
    setIsOpen(false);
    //   setOpenModal(false);

  }
  const closeModalForFaq = () => {
    setOpenModal(false);
  }

  // const term=()=>{
  //     Api.Terms().then((response)=>{

  //         console.log("terms",response.data.terms[0]);
  //         setTerms(response.data.terms[0]);

  //     })

  // }

  const Open = () => {
    Api.Terms().then((response) => {

      console.log("terms", response.data.terms[0]);
      setTerms(response.data.terms[0]);
      setIsOpen(true);

    })

  }

  const handleOutsideClick = () => {
    if (isOpen || openModal) {
      
      setIsOpen(false);
      setOpenModal(false);
      
    }
  }

  //     const ReactDOMServer = require('react-dom/server');
  //   const HtmlToReactParser = require('html-to-react').Parser;
  //   const htmlInput = term;
  //   const htmlToReactParser = new HtmlToReactParser();
  //   const reactElement = htmlToReactParser.parse(htmlInput);
  //   const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  //   console.log(reactHtml);
  const variable = parse(question);
  console.log("variblae", variable);
  return (
    <>
      <div className="col-md-6 col-xs-6" onClick={()=>{handleOutsideClick();}} > 
      {/* <div className="Faq-terms-btns">
        <div className="popup-box" onClick={() => handleOutsideClick()}>
          <div className="box"> */}
            <a href="#!" onClick={() => { faq(); }}>FAQs <img src={message} alt="message" /></a>

            <ReactModal isOpen={openModal} contentLabel="Example Modal" className="Modal">

              {/* <div style={{ width : '100%'}} >
                  {terms}
                  </div>
                  <button onClick={()=>{closeModal();}}>Close Modal</button> */}
              <div className="modal-container">
                <div className="modal-content">
                  <p className="modal-body">{variable}</p>
                  <div className="modal-footer">
                    <button className="modal-close" onClick={() => { closeModalForFaq(); }}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </ReactModal>

</div>

                                <div className="popup-box" onClick={() => handleOutsideClick()}>
                    <div className="box">
            <a href="#!" className="col-6" onClick={() => { Open(); }}><img src={notes} alt="notes" /> T’s & C’s</a>
            {/* {showTerm ? terms : ""} */}

            <ReactModal isOpen={isOpen} contentLabel="Example Modal" className="Modal">

                                                {/* <div style={{ width : '100%'}} >
                                  {terms}
                                  </div>
                                  <button onClick={()=>{closeModal();}}>Close Modal</button> */}
              <div className="modal-container">
                <div className="modal-content">
                  <p className="modal-body">{parse(terms)}</p>
                  <div className="modal-footer">
                    <button className="modal-close" onClick={() => { closeModal(); }}>
                      Close
                    </button>
                  </div>
                </div>
              </div>

            </ReactModal>

                                      {/* <ReactModal isOpen={isOpen} contentLabel="Example Modal"  className="Modal">

                          <div style={{ width : '100%'}} >
                          This is sample For Testing  fhsdfhsdfhsdhgjgldfghdfbjhkg ihggjghflhgsglghfsdklhsdghkshkjskshgkshdkghsk
                          </div>
                          <button onClick={()=>{closeModal();}}>Close Modal</button>
                          </ReactModal> */}

          </div>
        </div>
    </>

  );  

} export default Terms; 