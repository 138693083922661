import React, { useEffect } from "react";
import allogo from '../images/all-logo.png';
import handshake from '../images/hand-shake.png';
import '../css/custom.css';
import '../css/bootstrap.min';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../component/Loader.js";
import Cookies from "js-cookie";
// import Api from '../Api/Getapi.js';

function Intro (){

    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        setIsLoading(true);
        if(Cookies.get("response")===null || Cookies.get("ani")===undefined || Cookies.get("response")==="false")
        {
            navigate("/Notsub");
        }
        else
        {
        navigate("/home");
        setIsLoading(false);
        }
    },[])

    const elseRecord =(
        <>
        <div className="container">
        <div className="intro-screen">
        <div className="intro-logo"><img  alt="logo" src={allogo} /></div>
        <div className="intro-content"><img  alt="logo" src={handshake} />
        <h1>Welcome to<br/>
        Bigcash Games !</h1>
        <p>Play and Win Cash Prizes For<br />
        Reaching Higher Levels.</p></div>
        </div>
        </div>

        </>
    );
    
    return(
        <>
       {isLoading ? <Loader /> : elseRecord }
       </>
    );
}
export default Intro;