import React, { useEffect} from "react";
import bigcash from "../images/bigcash-logo.png";
// import Api from '../Api/Getapi.js';
// import { useParams } from "react-router-dom";
// import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const MainMethod=()=>{

//    const {msisdn} = useParams();
//    console.log(msisdn.substring(7));
// const pathname="/";

// const variable = window.location.search.split("?ani=");
// // variable.split("?ani=");
// const parameter = variable[1].split("&state=");
// const ani = parameter[0]
// console.log("ani",parameter[0]);
// console.log("state",parameter[1]);

// localStorage.setItem("ani",ani);

const navigate = useNavigate();

   useEffect(()=>{

    navigate("/Intro");
   
   });

    return (
        <>
        <div className="container">
    <div className="center-logo"><img alt="logo" src={bigcash} /></div>
    </div>
        </>
    );
}
export default MainMethod;