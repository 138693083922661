import React , {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import scoreicon from '../images/score-icon.png';
import menuicon from '../images/menu-icon.png';
import demoimg from '../images/demo-img.png';
import trophy from '../images/trophy.png';
import '../css/custom.css';
import '../css/bootstrap.min';
import Footer from '../component/footer.js';
import Api from '../Api/Getapi';
import Loader from '../component/Loader.js';
import { Link, useNavigate } from "react-router-dom";
import Menu from "../component/Menu.js";
import Cookies from "js-cookie";


const Score=()=>
{
  const [Score,setScore] = useState('');
  const [DayScore, setDayScore] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const navigate = useNavigate();

        useEffect (()=>{
          setIsLoading(true);
         Api.getLeaderBoard().then((response)=>{ 

          if(Cookies.get('response')===null || Cookies.get("ani")===undefined ||  Cookies.get("response")==="false")
          {
              navigate("/Notsub");
          }
          // console.log("res",response.data.dayScore);
         setDayScore(response.data.dayScore);

         console.log("Score",DayScore);
        
          setIsLoading(false);
         }).catch((error)=>{
            console.log(error);
         })
        },[]);

        useEffect (()=>{
          Api.Score().then((response)=>{ 
           console.log("res",response.data.score);
          setScore(response.data);
 
          console.log("UserScore",Score);
         
 
          }).catch((error)=>{
             console.log(error);
          })
         },[]);


       const AllRecord =( <>
        <Menu
        one="inactive"
        two="active"
        three="inactive"
        four="inactive"
        five="inactive"
        six="inactive"
      />
        
        <div className="container white-bg">
  <div className="cus-header">
   <div className="col-md-6 col-xs-6"><div className="page-icon"><img src={scoreicon} alt="scoreicon"/> Score</div>
  </div>
  <div class="col-md-5 col-xs-5">
    <a class="cus-menu navbar-toggle collapsed" href="#!"  data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false"><img src={menuicon} alt="menuicon"/>menu</a>
  </div>
</div>
<div className="container-area">
  <a href="#!" className="cus-btn-blue"> Your Performance Status</a>
 
  <div className="score-area">
    <img className="img-user" src={demoimg} alt="demoimg" />
   <h3> Hie Player </h3>
  <p> Congratulations on your new score!</p>
 <div className="user-score"><div className="blue-bg">User Score:	<div className="score">{Score.score}</div></div></div>
  <p>Beat the best score & become the highest!</p>
 
  <div className="score-row">
    <div className="col-md-5 col-xs-5"> <img src={trophy} alt="trophy" /></div>
  <div className="col-md-7 col-xs-7">
    <div className="best-score">Best Score to Beat:
  <div className="score">{DayScore[0]}</div>
  </div>  
</div>
</div>
  </div>
  <div className="cus-score-blue">
              <Link to="/home"> Continue Playing </Link>
            </div>
<Footer />
</div>
</div>
       </>);  
    return (
      <>
       {isLoading ? <Loader /> : AllRecord}
       </>
    );
}
export default Score;