import React from "react";
// import homeicon from '../images/home-icon.svg';
import homeicon from '../images/home-icon-svg.png'
import scoreicon from '../images/score-icon.svg'; 
import leadericon from '../images/leader-icon.svg'; 
import prizeicon from '../images/prize-icon.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/custom.css';
import '../css/bootstrap.min';
// import { BrowserRouter as Router,Link,Route } from 'react-router-dom';
import {Link} from 'react-router-dom';
// import Leaderfun from "./leaderboard";
// import App from "../App";
// import Score from '../component/Score.js';
// import Prize from '../component/Score.js';

// import bigcash from '../images/bigcash-logo.png';


function footer()
{
    // return 
    // (
    // <>
    // </>
    // );

    

    return(
        <>
       
                   
        <div className="footer-menu">
        {/* <div className="col-md-3 col-xs-3"><Link to="/" className="active"><img src={bigcash} alt="homeicon" /></Link></div> */}
         <div className="col-md-3 col-xs-3"><Link to="/home" className="active"><img src={homeicon} alt="homeicon" />Home</Link></div>
         <div className="col-md-3 col-xs-3"><Link to="/score" className="active"><img src={scoreicon} alt="scoreicon" />Score </Link></div>
      <div className="col-md-3 col-xs-3"><Link to="/leader" className="active"><img src={leadericon} alt="leaderblue" />Leaderboard </Link></div>
        <div className="col-md-3 col-xs-3"><Link to="/prize" className="active" ><img src={prizeicon} alt="prizeicon" />Prizes</Link></div>
         </div>
      
        </>
    );
}
export default footer;


{/* <Route path="/" element={<App />} exact className="active"></Route> */}