import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import homeicontop from '../images/home-icon-top.png';
import menuicon from '../images/menu-icon.png';
// import bigcashgame from '../images/big-cash-game.png';
import mediaplay from '../images/mediaplay.png';
import dots from '../images/dots-svg.png';
import '../css/custom.css';
import '../css/bootstrap.min';
import Footer from '../component/footer.js';
import Api from '../Api/Getapi.js';
import MenuButton from '../component/MenuButton.js';
import Loader from '../component/Loader.js';
import { useState } from 'react';
import Menu from './Menu';
import arrowleft from '../images/arrow-left.svg';
import bigcash from '../images/bigcash-logo.png';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



const Home=()=>
{

  const [ImageName,setImageName]=useState([]);

  const [ImageLink,setImageLink] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = () => {
    console.log("control inside toggleMenu");
    setIsMenuOpen(true);
    

  };
  
  const navigate = useNavigate();

  
  useEffect(()=>{
    setLoading(true);
    Api.Images().then(response=>
      {

        console.log("The value of Localstorage",window.localStorage.getItem('response'));
        if(Cookies.get('response')===null || Cookies.get("ani")===undefined  ||  Cookies.get("response")==="false")
        {
            navigate("/Notsub");
        }
      
      //stop loading when data is fetched
      // console.log(response.data);
     setImageName(response.data.imageName);
    //  console.log("Res",response.data.imageName.length);
     setImageLink(response.data.imagelink);
    //  console.log(Img.imageName);
    //  console.log(Img.imagelink);
    //  console.log("iamge",Img.imageName);
    //  console.log("link",Img.imagelink);
    // console.log("response",response.data.imageName);
    // SetImg(response.data);
      // console.log("name",ImageName);
      // console.log("link",ImageLink);
      // setLoading(false);
      setLoading(false)
  })
  
  },[]);

 
// const navigate = useNavigate();

 function Test(props)
  {
    
    // Temp.Sub().then((response)=>{
    //   // console.log("respose",response.data);
    //   if(response.data)
    //   {
    //     navigate("/");
    //   }
    //   else{
    //     console.log("inside function Home");
    //     navigate("/Checkuser")
    //   }
    // })

   console.log(props);
   
   const ani = Cookies.get("ani");
   console.log(ani);
   if(props===1){
   window.location.href = ImageLink[4]+"?msisdn="+ani+"&gameId="+props;
   }
   else if(props===2){
    window.location.href = ImageLink[5]+"?msisdn="+ani+"&gameId="+props;
  }
  else if(props===3){
    window.location.href = ImageLink[6]+"?msisdn="+ani+"&gameId="+props;

  }else if(props===4){
    window.location.href = ImageLink[7]+"?msisdn="+ani+"&gameId="+props;
  }
  };

  const sampleData = (<>
       <Menu
        one="active"
        two="inactive"
        three="inactive"
        four="inactive"
        five="inactive"
        six="inactive"
      />
    <div className="container white-bg" >
     <div className="cus-header">
       <div className="col-md-6 col-xs-6"><div className="page-icon"><img src={homeicontop} alt="homeicontop"/> Home</div>
     </div>
     <div class="col-md-5 col-xs-5">
     <a
        class="cus-menu navbar-toggle collapsed" 
        href="#!"
         data-toggle="collapse" 
         data-target="#bs-example-navbar-collapse-1" 
         aria-expanded="false"
      >
        <img src={menuicon} alt="Menu Icon" onClick={toggleMenu}/> menu</a>
      
     {/* <MenuButton /> */}
     </div>
    </div>
    <div className="container-area">
     <a href="#!" className="cus-btn-blue"> BigCash Games For You</a>
     <div className="game-area">
     <div className="col-md-6 col-xs-6">
       <div className="cus-game">
         <img alt="game-icon" src={ImageLink[0]}/>
         <h4>{ImageName[0]}</h4>
         <a href="#!" onClick={()=>{Test(1);}}> <img src={mediaplay} alt="mediaplay"/> Play Now</a>
       </div>
         <a href="#!" className="info-icon"><img  src={dots} alt="dots" /></a>
     </div>
     <div className="col-md-6 col-xs-6">
       <div className="cus-game">
         <img alt="game-icon" src={ImageLink[1]}/>
         <h4>{ImageName[1]}</h4>
         <a href="#!" onClick={()=>{Test(2);}}> <img src={mediaplay} alt="mediaplay"/> Play Now</a>
       </div>
        <a href="#!" className="info-icon"><img  src={dots} alt="dots" /></a>
     </div>
       <div className="col-md-6 col-xs-6">
       <div className="cus-game">
         <img alt="game-icon" src={ImageLink[2]} />
         <h4>{ImageName[2]}</h4>
         <a href="#!" onClick={()=>{Test(3);}}> <img src={mediaplay} alt="mediaplay"/> Play Now</a>
       </div>
        <a href="#!" className="info-icon"><img  src={dots} alt="dots"/></a>
     </div>
       <div className="col-md-6 col-xs-6">
       <div className="cus-game">
         <img alt="game-icon" src={ImageLink[3]}/>
         <h4>{ImageName[3]}</h4>
         <a href="#!" onClick={()=>{Test(4);}}> <img src={mediaplay} alt="mediaplay"/> Play Now</a>
       </div>
        <a href="#!" className="info-icon"><img  src={dots} alt="dots"/></a>
     </div>
    
    </div>
    <Footer />
    {/* <Api.Images/> */}
    {/* <Temp /> */}
    </div>
    </div>
    
    </>

  );

 
return(
  <>
   {isLoading ? <Loader /> : sampleData}
  </>
);
}
export default Home;