import React, { useState,useEffect }  from "react";
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../images/1.png';
import arrowleft from '../images/arrow-left.svg';
import bigcash from '../images/bigcash-logo.png';
import leader from '../images/leader.png';
import image5star from '../images/5star.png';
import image2 from '../images/2.png';
import image4star from '../images/4star.png';
import image4 from '../images/4.png';
import image3 from '../images/3.png';
import image5 from '../images/5.png';
import image3star from '../images/3star.png';
import image6 from '../images/6.png';
import image7 from '../images/7.png';
import image8 from '../images/8.png';
import image9 from '../images/9.png';
import image10 from '../images/10.png';
import image2star from '../images/2star.png';
import image1star from '../images/1star.png';
// import homeicon from '../images/home-white-icon.svg';
// import scoreicon from '../images/score-icon.svg';
// import leaderblue from '../images/leader-blue.svg';
// import prizezicon from '../images/prize-icon.svg';
// import menuicon from '../images/menu-icon.svg';
import '../css/custom.css';
import '../css/bootstrap.min';
import Footer from '../component/footer.js';
import apivalue from '../Api/Getapi.js'
import MenuButton from '../component/MenuButton.js';
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import $ from 'jquery';
import menuicon from '../images/menu-icon.png';
import Loader from "../component/Loader.js";
import { useNavigate } from "react-router-dom";
import Menu from "../component/Menu.js";
import Cookies from "js-cookie";




function Leaderfun()
{

  const [LeaderScore, setLeaderScore] = useState([])

  const [DayScore,SetDayScore] = useState([]);

  const [isLoading,setIsLoading] = useState(false);



  // const menuItems = [
  //   { label: 'Home', link: '/' },
  //   { label: 'Leader', link: '/leader' },
  //   { label: 'Score', link: '/score' },
  // ];

const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    apivalue.getLeaderBoard().then((response) => {
      
      if(Cookies.get("response")===null || Cookies.get("ani")===undefined || Cookies.get("response")==="false")
      {
          navigate("/Notsub");
      }
      console.log("res",response.data.monthScore);
      setLeaderScore(response.data.monthScore);
      SetDayScore(response.data.dayScore);
      // console.log("res",response.data.dayScore.length);
      setIsLoading(false);
    })
  }, []);





  // const MenuFunction =()=>{

  //   console.log("inside MenuFunction");
  //   return (<>
  //      <MenuButton />
  // </>
  //   );

  // }

  const Record = (
<>

<Menu
        one="inactive"
        two="inactive"
        three="active"
        four="inactive"
        five="inactive"
        six="inactive"
      />

  {/* // Main Code Start here  */}
      
      <div className="container white-bg">
  <div className="cus-header">
    <div className="col-md-7 col-xs-7 pl-0"><div className="page-icon"><img src={leader} alt="leader"/> Leaderboard</div>
  </div>
  <div class="col-md-5 col-xs-5">
    <a class="cus-menu navbar-toggle collapsed" href="#!"  data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false"><img src={menuicon} alt="menuicon"/>menu</a>
  </div>
</div>
<div className="container-area">
  <a href="#!" className="cus-btn-blue"> Top 10 Players Rankings</a>
  <p className="big-font">Climb the ranks and earn your spot at
the top of the leaderboard!</p>
  <div className="score-area-table">
   <div className="blue-row">
   <div className="col-md-3 col-xs-3">Position </div>
   <div className="col-md-3 col-xs-3">Player Rating </div>
   <div className="col-md-3 col-xs-3">Daily Score </div>
   <div className="col-md-3 col-xs-3">Monthly Score</div>
 </div>
<div className="white-bg-shadow">
<div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image1} alt="image1"/> </div>
   <div className="col-md-3 col-xs-3"> <img src={image5star} alt="5star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5">{DayScore[0]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5" style={{left: '-30' , width : '20px'}}> {LeaderScore[0]}</div>
 </div>
  </div>

  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image2} alt="image2" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image4star} alt="iamge4star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5">{DayScore[1]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[1]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image3} alt="iamge3"/> </div>
   <div className="col-md-3 col-xs-3"> <img src={image4star} alt="iamge4star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[2]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[2]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image4} alt="image4" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image4star} alt="iamge4star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[3]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[3]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image5} alt="image5"/> </div>
   <div className="col-md-3 col-xs-3"> <img src={image3star} alt="image3star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[4]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[4]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image6} alt="iamge6"/> </div>
   <div className="col-md-3 col-xs-3"> <img src={image3star} alt="image3star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[5]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[5]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image7} alt="iamge7" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image3star} alt="image3star"/></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[6]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5">{LeaderScore[6]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image8} alt="image8" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image2star} alt="iamge2star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[7]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[7]}</div>
 </div>
  </div>


  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image9} alt="image9" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image2star} alt="iamge2star"/ ></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[8]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5">{LeaderScore[8]}</div>
 </div>
  </div>

  <div className="rating-row"> 
  <div className="col-md-3 col-xs-3"><img src={image10} alt="image10" /> </div>
   <div className="col-md-3 col-xs-3"> <img src={image1star} alt="image1star" /></div>
   <div className="col-md-6 border-row col-xs-6">  <div className="col-md-5 col-xs-5"> {DayScore[9]}</div><div className="col-md-2 col-xs-2">|</div>
<div className="col-md-5 col-xs-5"> {LeaderScore[9]}</div>
 </div>
  </div>


</div>

<div className="cus-score-btn-blue">
<Link to="/score" > View My Score</Link>
  </div>

<Footer />


</div>
</div>
</div>

    </>

    );
  
  
  
  return (
    <>
    {isLoading ? <Loader /> : Record}
    </>
   

    );
}
export default Leaderfun;
