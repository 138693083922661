import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from '../component/Home.js';
import Leaderfun from "../component/leaderboard.js";
import Score from "../component/Score.js";
import Prize from "../component/Prize.js";
import Terms from "../component/Terms.js";
import Temp from "../component/Temp.js";
import sub from '../component/CheckSub';
import MainMethod from "../component/index.js";
import Intro from "../component/intro.js";
import Api from '../Api/Getapi.js';
import Notsub from "../component/NotSub.js";
import MenuButton from "../component/MenuButton.js";
import Loader from "../component/Loader.js";
import index from "../component/index.js";
import MenuTerm from "../component/MenuTerm.js";
import Faq from "../component/Faq.js";
// import useParams from 'react-router-dom';

const Routing=()=>{

    // console.log(window.location.search);
    // const loc = new URLSearchParams(window.location.pathname);
    // console.log(loc);

    // console.log(window.localStorage.getItem("ani"))

    // const variable = window.location.search.split("?ani=");
    // variable.split("?ani=");
    // const parameter = variable[1].split("&state=");
    // const ani = parameter[0]
    // console.log("ani",parameter[0]);
    // console.log("state",parameter[1]);
    
    // localStorage.setItem("ani",ani);
    // console.log(ani);
    return(
        <>
            <Router>
                <Routes>
                    <Route path= "/main" element={<MainMethod />} exact={true}/>
                    <Route path="/home" element={<Home/>} exact={true}></Route>
                    <Route path="/leader" element={<Leaderfun/>} exact={true} />
                    <Route path="/score" element={< Score />}  exact={true} />
                    <Route path="/prize" element={<Prize />} exact={true} />
                    <Route path="/terms" element={<Terms />} exact={true} />
                    <Route path="/checkSub" element={<Temp />} exact={true} />
                    {/* <Route path="/check" element={<sub.Sub />} exact ={true} /> */}
                    <Route path="/intro" element={<Intro />} exact={true} />
                    <Route path="/" element={<Api.CheckSub/>}  exact={true}/>
                    <Route path="/Notsub" element={<Notsub />} exact={true} />
                    {/* <Route path="/Menu" element={<MenuButton />} exact={true} /> */}
                    <Route path="/Loader" element={<Loader />} exact={true} />
                    {/* <Route path="/index" element={<index />} exact={true} /> */}
                    {/* <Route path="/test" element={<Test />} exact={true} /> */}
                    <Route path="/MenuTerm" element={<MenuTerm />} exact={true} />
                    <Route path="/faq" element={<Faq />} exact={true} />

                </Routes>
            </Router>
        </>
    );

}
export default Routing;